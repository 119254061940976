import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HOST_PATERN, trackByFn} from '@ft/core';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../services/products.service';

@Component({
    selector: 'ftm-certificate',
    templateUrl: './certificate.dialog.html',
    styleUrls: [
        './certificate.dialog.scss'
    ]
})
export class CertificateDialog implements OnInit {
    public loader: Subscription;
    public trackByIndex = trackByFn('');
    public hostPattern: string = HOST_PATERN;
    public certificate: { common_name?: string, dns_list?: string[], using_valid_encrypt?: boolean, ip_list?: string[] } = {};

    constructor(private dialogRef: MatDialogRef<CertificateDialog>, private service: ProductsService) {

    }

    ngOnInit() {
        this.loader = this.service.getLastCertificate()
            .subscribe(data => this.certificate = data);
    }

    removeIp(index, ev) {
        this._handleListItemRemove('ip_list', index, ev);
    }

    removeDns(index, ev) {
        this._handleListItemRemove('dns_list', index, ev);
    }

    submit() {
        this.loader = this.service.rebuildCertificate(this.certificate)
            .subscribe(data => this.dialogRef.close(data));
    }

    private _handleListItemRemove(key, index, ev) {
        ev.stopPropagation();
        this.certificate[key].splice(index, 1);
        if (this.certificate[key].length === 0) this.certificate[key] = [''];
    }
}
