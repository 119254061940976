import {Component, OnInit, OnDestroy, ElementRef, HostListener} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConnectDialog, DialogData} from '../../dialogs/connect.dialog/connect.dialog';
import {assign, debounce, chain} from 'lodash-es';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {getResizeObserver, SMALL_DIALOG} from '@ft/core';

import {Terminal} from 'xterm';
import {FitAddon} from 'xterm-addon-fit';
import {WebglAddon} from 'xterm-addon-webgl';
import {WebLinksAddon} from 'xterm-addon-web-links';

import {SSH_MESSAGE_TYPES} from '../../models/enums';
import {SshService} from '../../services/ssh.service';
import {SshMessage, TERMINAL_OPTIONS} from '../../models/ssh-message';

import {environment} from '../../../../environments/environment';
import {SSH_CMD} from '../../models/cmd';



@Component({
    selector: 'ftm-ssh',
    templateUrl: './ssh.component.html',
    providers: [
        SshService
    ],
    styleUrls: ['./ssh.component.scss']
})
export class SshComponent implements OnInit, OnDestroy {
    public terms = [];
    public index = 0;
    public connection: string;
    public isFirstConnect = true;
    public sshCmd: any[] = SSH_CMD;

    private resizeObserver: any;
    private debounceDialog: any;
    private terminals: { [key: string]: Terminal } = {};
    private terminalFits: { [key: string]: FitAddon } = {};

    @HostListener('window:beforeunload')
    beforeUnloadHandler() {
        this._disconnect();
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        navigator.clipboard.readText().then(
            // replace is to fix double enter
            text => this.sshService.write(this.terms[this.index], text.replace(/(\n)/gm, ''))
        );
    }

    constructor(
        public dialog: MatDialog,
        private sshService: SshService,
        private elementRef: ElementRef,
        private snackBar: MatSnackBar,
        private translate: TranslateService
    ) {
        this.openDialog();
        this.debounceDialog = debounce(() => this.disc(), 5);
    }

    ngOnInit() {
        this.resizeObserver = getResizeObserver(this.elementRef.nativeElement, 150)
            .subscribe(() => this._handleResize());

        this.sshService.messages
            .pipe(
                map(res => SshMessage.toMap(res))
            )
            .subscribe(
                data => {
                    switch (data.type) {
                        case SSH_MESSAGE_TYPES.CONNECT:
                            if (!data.hasError) {
                                this.connection = data.connection;
                                this.openTerminal();
                            } else {
                                this.isFirstConnect = false;
                                this.snackBar.open(this.translate.instant('ssh.error_connect'), null, {
                                    duration: 4000,
                                });
                            }
                            break;

                        case SSH_MESSAGE_TYPES.OPEN:
                            if (data.channel) this.handelTerminal(data.channel);
                            else { this.snackBar.open(this.translate.instant('ssh.error_open'), null, {
                                duration: 4000,
                            });
                            }
                            break;

                        case SSH_MESSAGE_TYPES.READ:
                            this.terminals[data.channel].write(data.str);
                            break;

                    }

                }
            );
    }

    ngOnDestroy() {
        this._disconnect();
        this.resizeObserver.unsubscribe();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ConnectDialog, assign(SMALL_DIALOG, {
            autoFocus: false,
            disableClose: true,
            data: this._connectionCredentials
        }));


        dialogRef.afterClosed()
            .subscribe(data => {
                if (data) this.sshService.connect(data);
                else this.isFirstConnect = false;
            });
    }

    handelTerminal(channel) {
        this.terms.push(channel);
        this.terminalFits[channel] = new FitAddon();
        this.terminals[channel] = new Terminal(TERMINAL_OPTIONS);

        this.terminals[channel].loadAddon(new WebLinksAddon() as any);
        this.terminals[channel].loadAddon(this.terminalFits[channel]);

        this.index = this.index + 1;

        setTimeout(() => {
            const container = document.getElementById(channel);

            this.terminals[channel].open(container as any);
            this.terminals[channel].loadAddon(new WebglAddon() as any);

            this.readTerminal(channel);

            this.terminals[channel].onData(str => {
                this.sshService.write(channel, str);
            });

            this.terminals[channel].onResize(geom => {
                this.sshService.resize(channel, geom.cols, geom.rows);
            });

            this.terminals[channel].onSelectionChange(() => {
                this.terminals[channel].getSelection();
                document.execCommand('copy');
            });

            setTimeout(() => (this.terminalFits[channel] as any).fit());
        });

    }

    readTerminal(channel) {
        this.sshService.read(channel);
    }

    disc() {
        for (const t of this.terms) {
            this.terminalFits[t].dispose();
            this.terminals[t].dispose();

            delete this.terminals[t];
            delete this.terminalFits[t];
        }
        this.terms.splice(0, this.terms.length);
        this._disconnect();
        this.sshService.closeWS();

        this.openDialog();
    }

    closeTerminal(channel) {
        const i = this.terms.indexOf(channel);
        this.terminalFits[channel].dispose();
        this.terminals[channel].dispose();

        delete this.terminals[channel];
        delete this.terminalFits[channel];

        this.terms.splice(i, 1);

        this.sshService.close(channel);
    }

    onLinkClick(event) {
        const channel = this.terms[event.index];
        this.terminals[channel].focus();
    }

    copyCmd(channel, cmd) {
        this.sshService.write(channel, cmd);
        setTimeout(() => this.terminals[channel].focus(), 50);
    }

    openTerminal() {
        this.sshService.open();
    }

    private _handleResize() {
        chain(this.terminalFits).values().invokeMap('fit').value();
    }

    private _disconnect() {
        this.sshService.disconnect();
    }

    private get _connectionCredentials(): DialogData {
        return environment.sshDialogData;
    }
}
