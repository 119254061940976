import {get} from 'lodash-es';

declare type LoadStateType =
    'active'
    | 'inactive'
    | 'activating'
    | 'deactivating'
    | 'failed'
    | 'not-found'
    | 'running'
    | 'dead';


export class ServiceModel {
    public Id: string;
    public User: string;
    public Group: string;

    public ExecMainPID: number;
    public FragmentPath: string;
    public UnitFileState: string;

    public LoadState: LoadStateType;
    public ActiveState: LoadStateType;
    public SubState: LoadStateType;

    constructor(data) {
        this.Id = get(data, 'Id');
        this.User = get(data, 'User');
        this.Group = get(data, 'Group');
        this.ExecMainPID = get(data, 'ExecMainPID');
        this.FragmentPath = get(data, 'FragmentPath');
        this.UnitFileState = get(data, 'UnitFileState');

        this.LoadState = get(data, 'LoadState');
        this.ActiveState = get(data, 'ActiveState');
        this.SubState = get(data, 'SubState');
    }

    get notSuccess(): boolean {
        return 'dead' === this.LoadState;
    }

    get notRunning(): boolean {
        return 'dead' === this.SubState || this.LoadState === 'not-found';
    }

    get isDisabled(): boolean {
        return this.UnitFileState === 'disabled';
    }

    get isManager(): boolean {
        return this.Id === 'ft-manager.service';
    }

    get isServiceNotFound(): boolean {
        return this.LoadState === 'not-found';
    }
}
