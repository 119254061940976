import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {noop, Observable, Subject, throwError} from 'rxjs';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {GeneralPurposeService} from '@ft/core';
import {FileObject} from '../classes/folder';
import {assign} from 'lodash-es';
import {ActivatedRoute, Router} from '@angular/router';
import {MomentDateAdapter} from '@angular/material-moment-adapter';


@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    public rootUrl = ['/file-manager/main'];

    private _fileUrl = '/api/file-manager/main/';

    private readonly _moveForceLabel: string;
    private readonly _moveLabel: string;
    private readonly _movePlaceholderLabel: string;

    constructor(
        private _http: HttpClient, private _ftService: GeneralPurposeService, private _translate: TranslateService,
        private _router: Router, private _route: ActivatedRoute, private _adapter: MomentDateAdapter
    ) {
        this._moveForceLabel = _translate.instant('file_manager.move_file_force');
        this._moveLabel = _translate.instant('file_manager.move');
        this._movePlaceholderLabel = _translate.instant('file_manager.move_file_placeholder');
    }

    read(pathF, isFolder): Observable<any> {
        let observable;
        if (isFolder) observable = this._http.get(this._fileUrl, {params: {path: pathF}});
        else observable = this._http.get(`${this._fileUrl}${pathF}/`);
        return observable.pipe(
            map(
                (data) => new FileObject(data)
            )
        );
    }

    create(path): Observable<any> {
        return this._ftService.openPromptDialog('file_manager.add_file_title', 'file_manager.add_file_placeholder')
            .pipe(
                mergeMap(name => this._http.post(this._fileUrl, {path, name}))
            );
    }

    delete(file): Observable<any> {
        return this._http.delete(this._fileUrl, {params: {path: file.handled_path}});
    }

    upload(path, files): Observable<any> {
        const body: FormData = new FormData();
        const subject = new Subject();
        this._ftService.openProgressDialog(subject);

        if (files instanceof Array) files.forEach(file => body.append(file.name, file));
        else body.append(files.name, files);

        const config = new HttpRequest('POST', `${this.getUrl(path)}upload/`, body, {
            reportProgress: true
        });

        return this._http.request(config)
            .pipe(
                tap(event => event.type === HttpEventType.UploadProgress ? subject.next(event) : noop()),
                filter(event => event.type === HttpEventType.Response),
                tap(() => subject.complete()),
                catchError(err => {
                    subject.complete();
                    return throwError(err);
                }),
            );

    }

    move(source, target): Observable<any> {
        return this._http.post(`${this.getUrl(source)}move/`, {target});
    }

    moveForce(source, target): Observable<any> {
        return this._ftService.openConfirmDialog(this._moveForceLabel)
            .pipe(
                mergeMap(
                    () => this._http.post(`${this.getUrl(source)}move/`, {target, force: true})
                )
            );
    }

    moveOrReplace(file): Observable<any> {
        return this._ftService.openPromptDialog(this._moveLabel, this._movePlaceholderLabel, null, null, file.dir)
            .pipe(
                mergeMap(targetDir => this.moveOverwrite(file.path, targetDir)),
            );
    }

    moveOverwrite(src, target): Observable<any> {
        return this.move(src, target)
            .pipe(
                catchError(err => {
                    if (err.error.description === 'path_not_found') return throwError(err);
                    else return this.moveForce(src, target);
                }),
            );
    }

    rename(file): Observable<any> {
        return this._ftService.openPromptDialog('file_manager.rename', 'file_manager.rename_file_placeholder', null, null, file.name)
            .pipe(
                mergeMap((name) => this._http.post(`${this.getUrl(file.path)}rename/`, {name}))
            );
    }

    search(path, search): Observable<any> {
        return this._http.get(`${this.getUrl(path)}search/`, {params: {search}});
    }

    download(file) {
        return this._ftService.download(`${this.getUrl(file.path)}download/`, `${file.name}${file.is_folder ? '.zip' : ''}`)
            .pipe(
                catchError(data => this.readBlobAsText(data.error).pipe(
                    mergeMap((result: string) => throwError(JSON.parse(result))))
                )
            );
    }

    readFile(path) {
        return this._http.get(`${this.getUrl(path)}download/?t=${this._adapter.today().valueOf()}`, {responseType: 'blob'})
            .pipe(
                mergeMap((blob) => this.readBlobAsText(blob))
            );
    }

    readBlobAsText(blob) {
        return new Observable(obs => {
            const reader = new FileReader();

            reader.onerror = err => obs.error(err);
            reader.onabort = err => obs.error(err);
            reader.onload = () => obs.next(reader.result);
            reader.onloadend = () => obs.complete();

            return reader.readAsText(blob);
        });
    }

    saveFileContent(file, content) {
        return this._http.post(`${this.getUrl(file.path)}save-file/`, {content});
    }

    getUrl(path) {
        return `${this._fileUrl}${btoa(path)}/`;
    }

    changeUrl() {
        const snapshot = this._route.snapshot.queryParams;
        const queryParams = assign({}, {path: snapshot.path}, snapshot.folder ? {} : {folder: true});
        this._router.navigate(this.rootUrl, {queryParams}).then();
    }

}
