import {Injectable} from '@angular/core';
import {FtWsService, GeneralPurposeService, pushOrUpdate} from '@ft/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {chain, clone, noop, orderBy} from 'lodash-es';
import {ProductModel} from '../classes/product.model';
import {catchError, filter, map, mergeMap, tap,} from 'rxjs/operators';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {ExecutionMessage} from '../classes/execution-message.model';
import {ServiceModel} from '../classes/service.model';

const EMPTY_SSL_CONFIG = {
    common_name: '',
    dns_list: [''],
    ip_list: [''],
};

@Injectable()
export class ProductsService {
    private _baseUrl = '/api/products';
    public products$: BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>([]);
    public currentProduct$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(null);

    constructor(
        private _ws: FtWsService,
        private _http: HttpClient,
        private _generalPurpose: GeneralPurposeService
    ) {
    }

    public canExecute(): Observable<boolean> {
        return this._generalPurpose.getByEvent('packages.can_install');
    }

    public executionNotifySubscription(): Observable<boolean> {
        return this._ws.observe('packages.execution_notify')
            .pipe(
                mergeMap(() => this.canExecute())
            );
    }

    public getProducts(): Observable<ProductModel[]> {
        return this._generalPurpose.getByHttp(`${this._baseUrl}/product/`)
            .pipe(
                map(data => data.map(item => new ProductModel(item))),
                map(data => orderBy(data, ['is_manager', 'name'], ['asc', 'asc'])),
                tap(products => this.products$.next(products))
            );
    }

    public getProductServices(product: ProductModel): Observable<ServiceModel[]> {
        return this._generalPurpose.getByHttp(`${this._baseUrl}/product/${product.id}/services/`)
            .pipe(
                map(data => data.map(item => new ServiceModel(item)))
            );
    }

    public updateProduct(product: ProductModel): Observable<ProductModel> {
        return this._generalPurpose.postHttp(`${this._baseUrl}/product/`, product)
            .pipe(
                map(data => new ProductModel(data)),
                tap(item => {
                    const products = pushOrUpdate(this.products$.getValue(), item);
                    this.products$.next(products);
                })
            );
    }

    public getProductPackages(packageId: number, type: string): Observable<any[]> {
        const url = `${this._baseUrl}/package/?product=${packageId}&type=${type}`;
        return this._generalPurpose.getByHttp(url);
    }

    public uploadPackage(packageId: number, type: string, packageFile: File): Observable<any> {
        const data: FormData = new FormData();
        data.append('package', packageFile, packageFile.name);

        const subject = new Subject();
        const config = new HttpRequest('POST', `${this._baseUrl}/package/?product=${packageId}&type=${type}`, data, {
            reportProgress: true
        });

        this._generalPurpose.openProgressDialog(subject);

        return this._http.request(config)
            .pipe(
                tap(event => event.type === HttpEventType.UploadProgress ? subject.next(event) : noop()),
                filter(event => event.type === HttpEventType.Response),
                tap(() => subject.complete()),
                catchError(err => {
                    subject.complete();
                    return throwError(err);
                }),
            );
    }

    public deletePackage(item) {
        return this._generalPurpose.openConfirmDialog('products.remove_package', item)
            .pipe(
                mergeMap(() => this._deletePackageItem(item))
            );
    }

    public validatePackageLicence(item: any, licenceKey: string) {
        return this._generalPurpose.getByEvent('packages.validate_licence', {pk: item.id, key: licenceKey});
    }

    public startExecution(item, licenceKey: string): Observable<ExecutionMessage> {
        const event = `packages.${this.currentProduct$.getValue().is_manager ? 'manager' : 'product'}_execution_process`;
        return this._generalPurpose.getByEvent(event, {pk: item.id, key: licenceKey})
            .pipe(
                map(data => new ExecutionMessage(data)),
                tap(data => {
                    if (data.product) {
                        const products = pushOrUpdate(this.products$.getValue(), data.product);
                        this.products$.next(products);
                    }
                })
            );
    }

    public updateLicenceKey(item, licenceKey: string): Observable<any> {
        return this._generalPurpose.getByEvent('packages.update_licence', {pk: item.id, key: licenceKey});
    }

    public handleService(service, action) {
        return this._generalPurpose.getByEvent(`packages.${action}_service`, {service})
            .pipe(
                mergeMap(() => this.getProductServices(this.currentProduct$.getValue()))
            );
    }

    private _deletePackageItem(item) {
        return this._generalPurpose.deleteHttp(`${this._baseUrl}/package/`, item);
    }

    // ssl build
    public getLastCertificate() {
        return this._generalPurpose.getByEvent('packages.ssl_certificate_config')
            .pipe(
                map(config => config ? config : clone(EMPTY_SSL_CONFIG)),
                map(config => {
                    config.ip_list = this._getProductsItems(config.ip_list, 'serve_ip');
                    config.dns_list = this._getProductsItems(config.dns_list, 'serve_dns');
                    return config;
                })
            );
    }

    public rebuildCertificate(config) {
        return this._generalPurpose.getByEvent('packages.rebuild_ssl', config);
    }

    private _getProductsItems(configItems, key): any[] {
        return chain(this.products$.getValue())
            .map(key)
            .concat(configItems)
            .uniq()
            .compact()
            .thru(ips => ips.length === 0 ? [''] : ips)
            .value();
    }

}
