import {Component, OnDestroy, OnInit} from '@angular/core';
import {isString} from 'lodash-es';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {GeneralPurposeService} from '@ft/core';

import {ProductModel} from '../../classes/product.model';
import {ProductsService} from '../../services/products.service';
import {FileManagerService} from '../../../file-manager/services/file-manager.service';
import {DateAdapter} from '@angular/material/core';

@Component({
    selector: 'ftm-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, OnDestroy {
    // empty init to avoid issue
    public product: ProductModel = new ProductModel({});

    public currentPath: string;
    public content: any = '';
    public loader: Subscription;


    private _subscription: Subscription;

    constructor(
        private _service: ProductsService,
        private _adapter: DateAdapter<any>,
        private _fmService: FileManagerService,
        private _generalPurpose: GeneralPurposeService) {
    }

    ngOnInit() {
        this._subscription = this._service.currentProduct$
            .pipe(
                filter(product => !!product)
            )
            .subscribe(
                product => {
                    this.product = product;
                    if (this.product.hasLogs) this.loadContent(0);
                }
            );
    }

    ngOnDestroy() {
        if (this._subscription) this._subscription.unsubscribe();
    }

    getFileName(file: string): string {
        return file.split('\\').pop().split('/').pop();
    }

    loadContent(index: number | string) {
        this.currentPath = isString(index) ? index : this.product.logs[index];
        this.loader = this._fmService.readFile(this.currentPath)
            .subscribe(data => this.content = data);
    }

    downloadFile() {
        const url = `${this._fmService.getUrl(this.currentPath)}download/`;
        this.loader = this._generalPurpose.download(url, this.getFileName(this.currentPath))
            .subscribe(download => download());
    }
}
