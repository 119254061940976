import {Component, OnInit} from '@angular/core';
import {assign, concat, without} from 'lodash-es';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {GeneralPurposeService, pushOrUpdate, SMALL_DIALOG, TableColumnDefinition} from '@ft/core';
import {PRINTERS_COLUMNS} from '../../models/columns';
import {PrintersService} from '../../services/printers.service';
import {MatDialog} from '@angular/material/dialog';
import {PrinterProfileDialog} from '../../dialogs/printer-profile/printer-profile.dialog';

@Component({
    selector: 'ftm-printers',
    templateUrl: './printers.component.html',
    styleUrls: [
        './printers.component.scss'
    ]
})
export class PrintersComponent implements OnInit {
    public source$: Observable<any[]> = of([]);
    public columns: TableColumnDefinition[];

    private _items: any[] = [];
    private _actionColumn: TableColumnDefinition = {
        label: '',
        key: 'actions',
        type: 'actions',
        actions: [
            {
                icon: 'mdi-pencil',
                method: item => this.handleProfile(item)
            },
            {
                class: 'mat-warn',
                icon: 'mdi-delete',
                method: item => this.removeProfile(item),
            }
        ]
    };

    constructor(
        private _dialog: MatDialog,
        private _service: PrintersService,
        private _generalPurpose: GeneralPurposeService,
    ) {
        this.columns = concat(PRINTERS_COLUMNS, this._actionColumn);
    }

    public ngOnInit(): void {
        this.source$ = this._service.getProfiles()
            .pipe(
                tap(data => this._items = (data as any))
            );
    }

    public handleProfile(profile = {}) {
        const dialogRef = this._dialog.open(PrinterProfileDialog, assign(SMALL_DIALOG, {
            data: {profile}
        }));

        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this._items = pushOrUpdate(this._items, data);
                    this.source$ = of(this._items);
                }
            }
        );
    }

    public removeProfile(item) {
        this._generalPurpose.openConfirmDialog('printing.remove_profile_confirm', item)
            .pipe(
                mergeMap(() => this._service.removeProfile(item)),
                map(() => without(this._items, item))
            )
            .subscribe(items => {
                this._items = items;
                this.source$ = of(this._items);
            });
    }

}
