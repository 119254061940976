import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {trackByFn} from '@ft/core';
import {FileManagerService} from '../../services/file-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {reject} from 'lodash-es';
import * as dragula from 'dragula';
import {Drake} from 'dragula';


import {FileObject} from '../../classes/folder';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'ftm-folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit, OnDestroy {
    public folder: FileObject;
    public trackByFn: any = trackByFn('path');

    private drake: Drake;

    constructor(
        private _snackBar: MatSnackBar,
        private _elementRef: ElementRef,
        private _fmService: FileManagerService,
        private _translate: TranslateService,
    ) {
    }

    @Input('folder') set handleFolders(items) {
        this.folder = items;
    }

    ngOnInit() {
        this.drake = this.drakeInstance();

        this.drake.on('drag', (el, source) => {
            source.classList.add('selected-file');
        });

        this.drake.on('over', (el, container, source) => {
            if (container !== source && (container.getAttribute('permission') === 'true')) container.classList.add('selected-folder');
        });

        this.drake.on('out', (el, container, source) => {
            if (container !== source) container.classList.remove('selected-folder');
        });

        this.drake.on('cancel', (el, container, source) => {
            container.classList.remove('selected-file');
        });

        this.drake.on('shadow', el => {
            el.remove();
        });

        this.drake.on('drop', (el, target, source) => {
            const src = source.getAttribute('path');
            const trg = target.getAttribute('path');

            if (target.getAttribute('permission') === 'true' && source.getAttribute('permission') === 'true') {
                this._fmService.moveOverwrite(src, trg)
                    .subscribe(
                        res => this.folder.children = reject(this.folder.children, res),
                        err => {
                            if (err !== false) this._snackBar.open(this._translate.instant(`file_manager.${err.error.description}`));
                        }
                    );
            }
        });
    }

    drakeInstance(): Drake {
        return dragula([this._elementRef.nativeElement], {
            isContainer: el => el.classList.contains('folder') || el.classList.contains('file'),
            accepts: (el, target, source, sibling) => target.classList.contains('folder'),
            invalid: (el, handle) => {
                return el.classList.contains('ft-empty-content') ||
                    el.parentElement.classList.contains('parent-folder') ||
                    el.parentElement.classList.contains('disabled');
            },

            copy: true,
            revertOnSpill: true,
        });
    }

    ngOnDestroy() {
        this.drake.destroy();
    }

}
