import {Component, OnInit} from '@angular/core';
import {ProductsService} from '../../services/products.service';
import {ProductModel} from '../../classes/product.model';
import {NavigationEnd, Router} from '@angular/router';
import {trackByFn} from '@ft/core';
import {debounce, filter, tap} from 'rxjs/operators';
import {merge, timer} from 'rxjs';

@Component({
    selector: 'ftm-products-main',
    templateUrl: './main.component.html',
    styleUrls: [
        './main.component.scss'
    ]
})
export class ProductsMainComponent implements OnInit {
    public products: ProductModel[] = [];
    public trackByFn = trackByFn('id');

    constructor(private service: ProductsService, private router: Router) {
    }

    ngOnInit() {
        this.routerEvents
            .subscribe(() => {
                const currentProduct = this.service.currentProduct$.getValue() || this.products[0];
                const defaultLinkUrl = this.router.createUrlTree(
                    [currentProduct.path, currentProduct.defaultLink.path],
                    {queryParams: currentProduct.defaultLink.params}
                ).toString();

                if (this.router.url === '/products' || this.router.url === currentProduct.path) {
                    return this.router.navigateByUrl(defaultLinkUrl);
                }
            });
    }

    private get routerEvents() {
        const routerEvent = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        return merge(routerEvent, this.service.products$, this.service.currentProduct$)
            .pipe(
                debounce(() => timer(50)),
                tap(() => this.products = this.service.products$.getValue())
            );
    }

}
