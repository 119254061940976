<div class="ft-parent-layout ft-monaco-container mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <nav mat-tab-nav-bar backgroundColor="accent" fxLayout="column">
        <a mat-tab-link *ngFor="let link of products; trackBy: trackByFn"
           [routerLink]="link.path"
           routerLinkActive #rla="routerLinkActive"
           [active]="rla.isActive">
            {{link.name}}
        </a>
    </nav>
    <router-outlet></router-outlet>
</div>